.App-link {
  color: #61dafb;
}

.navbar-nav {
  text-align: left;
  margin-left: 0;
}
.navbar-nav .nav-link {
  cursor: pointer;
  padding-left: 10px;
}
#tour_card {
  margin: 0;
}

/* Audio Player & Track Listing */
#audio_player {
  padding: 0;
  margin: 10px 0;
  text-align: left;
  position: sticky;
  top: 70px;
  height: 40px;
}
#audio_player a {
  cursor: pointer;
}
#audio_list {
  font-size: .75em;
}
#audio_list li {
  border-bottom: solid 1px #333333;
}

.react-player audio {
  height: revert;
}

.ku-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}